import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/react'
import {EndpointBuilder} from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import {setCookie} from 'typescript-cookie'

import {apiEndPoints} from '../../libs/consts'
import {api, BASE_API_URL} from '../../libs/utils/api'

import {secureQueryBuilder} from './_api'
import {BaseQueryArg} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {access} from "node:fs";
import {IAccesses} from "../../types/types";

interface IKeypairsData {
    privileges: string[]
}

export type TUpdatePassword = {
    otp_code: string
    old_password: string
    password: string
    repeated_password: string
}

class Base {
    get_keypair(data: IKeypairsData) {
        return api
            .post(apiEndPoints.GET_KEYPAIR, {
                privileges: data.privileges,
            })
            .then(({ data }) => data)
    }
    fetch_course() {
        return api
            .get(apiEndPoints.GET_COURSE)
            .then(({ data }) => data)
    }
    appeal(data: {
        external_tx_id: string
        message: string
        file: string
    }) {
        return api
            .post(apiEndPoints.APPEAL, data)
            .then(({ data }) => data)
    }
    get_reports() {
        return api
            .get(apiEndPoints.GET_REPORTS)
            .then(({ data }) => data)
    }
    get_connection_status() {
        return api
            .get(apiEndPoints.GET_CONNECTION_STATUS)
            .then(({ data }) => data)
    }
}

export default new Base()


const secureQuery = secureQueryBuilder(BASE_API_URL)

export const baseApi = createApi({
    baseQuery: secureQuery,
    reducerPath: 'baseApi',
    tagTypes: ['Base'],
    endpoints:
        (build:
            EndpointBuilder<BaseQueryFn<string | FetchArgs, {}, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
                'Base', 'baseApi'>,
        ) => ({
            authorization: build.mutation<any, { email: string, password: string, otp_code: string }>({
                query(body) {
                    return {
                        url: apiEndPoints.SIGN_IN,
                        method: 'POST',
                        body,
                    }
                },
                transformResponse: (response: { access_token: string, refresh_token: string, fingerkey: string, is_settlement_native: boolean }) => {
                    if (window.location.host === 'localhost:12003') {
                        setCookie('access_token', response.access_token, { expires: 7 })
                        setCookie('refresh_token', response.refresh_token, { expires: 1 })
                    } else {
                        setCookie('access_token', response.access_token, { expires: 7, domain: '.expay.cash' })
                        setCookie('refresh_token', response.refresh_token, { expires: 1, domain: '.expay.cash' })
                    }
                        setCookie('fingerkey', response.fingerkey, { expires: 365 })
                        setCookie('is_fiat', response.is_settlement_native)
                    },
                invalidatesTags: () => [{ type: 'Base', id: 'ID' }],
            }),
            subUserAuthorization: build.mutation<any, { email: string, password: string, otp_code: string }>({
                query(body) {
                    return {
                        url: apiEndPoints.SUBUSER_LOGIN,
                        method: 'POST',
                        body,
                    }
                },
                transformResponse: (response: { access_token: string, refresh_token: string, fingerkey: string, is_settlement_native: boolean, accesses: IAccesses }) => {
                    if (window.location.host === 'localhost:12003') {
                        setCookie('access_token', response.access_token, { expires: 7 })
                        setCookie('refresh_token', response.refresh_token, { expires: 1 })
                    } else {
                        setCookie('access_token', response.access_token, { expires: 7, domain: '.expay.cash' })
                        setCookie('refresh_token', response.refresh_token, { expires: 1, domain: '.expay.cash' })
                    }
                    setCookie('fingerkey', response.fingerkey, { expires: 365 })
                    setCookie('is_fiat', response.is_settlement_native)
                    },
                invalidatesTags: () => [{ type: 'Base', id: 'ID' }],
            }),
            signup: build.mutation<{ otp_url: string}, { email: string, password: string, repeated_password: string, invite_code: string, service_name: string }>({
                query(body) {
                    return {
                        url: apiEndPoints.SIGN_UP,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: () => [{ type: 'Base', id: 'ID' }],
            }),
            refresh: build.query<any, null>({
                query() {
                    return {
                        url: apiEndPoints.REFRESH_TOKEN,
                        method: 'POST',
                    }
                },
                transformResponse: (response: { access_token: string, refresh_token: string }) => {
                    if (window.location.host === 'localhost:3000') {
                        setCookie('access_token', response.access_token, { expires: 7 })
                        setCookie('refresh_token', response.refresh_token, { expires: 1 })
                        } else {
                            setCookie('access_token', response.access_token, { expires: 7, domain: '.expay.cash' })
                            setCookie('refresh_token', response.refresh_token, { expires: 1, domain: '.expay.cash' })
                        }
                },
                providesTags: () => [{ type: 'Base', id: 'ID' }],
            }),
            logout: build.query<any, void>({
                query() {
                    return {
                        url: apiEndPoints.LOGOUT,
                        method: 'POST',
                    }
                },
                providesTags: () => [{ type: 'Base', id: 'ID' }],
            }),
            updatePassword: build.mutation<{ private_key: string, public_key: string  }, TUpdatePassword>({
                query(body) {
                    return {
                        url: apiEndPoints.UPDATE_PASSWORD,
                        method: 'PATCH',
                        body,
                    }
                },
                transformResponse: (response: { private_key: string, public_key: string  }) => response,
                invalidatesTags: () => [{ type: 'Base', id: 'ID' }],
            }),
            getUserInfo: build.query({
                query: () => ({
                    url: apiEndPoints.GET_USER_INFO,
                    method: 'GET',
                }),
                transformResponse: (response: {accesses: any}) => {
                    return response
                },
            }),
        }),
})

export const {

    useSubUserAuthorizationMutation,
    useGetUserInfoQuery,
    useLazyGetUserInfoQuery,
    useAuthorizationMutation,
    useSignupMutation,
    useRefreshQuery,
    useUpdatePasswordMutation,
    useLazyLogoutQuery,
    useLazyRefreshQuery,
} = baseApi
